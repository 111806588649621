<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div class="query">
          <!-- <el-button type="primary" @click="printPdf2()"
            >保存为 PDF
          </el-button> -->
          <el-button type="primary" @click="getPdfFromHtmlFun()"
            >保存为 PDF
          </el-button>

          <el-button type="" @click="$router.go(-1)">返回上一页 </el-button>
        </div>
        <div class="htmltopdfContent" id="htmltopdfContent">
          <div class="reportOnePage reportPage">
            <div class="jyz">
              <div>GENCODE Release 46 (GRCh38.p14)</div>
              <div>Bioinformatic analysis by The PredictHealth LLC</div>
            </div>
            <div class="reportInfo">
              <div class="userName">{{ reportInfo.name }}</div>
              <div class="sampleId">Sample ID：{{ reportInfo.sampleId }}</div>
              <div class="sampleTime">
                采样时间：{{ reportInfo.samplingTime | dateFormat3 }}
              </div>
              <div class="reportTime">
                报告时间：{{ reportInfo.reportTime | dateFormat3 }}
              </div>
            </div>
          </div>
          <div class="reportOtherPage reportPage need">
            <div class="pageTitle" style="color: #7eaa55">
              全基因组建库和测序质量报告
            </div>
            <div style="line-height: 70px; font-size: 26px">
              <div style="text-indent: 52px">
                很高兴可以邀请您与 Predicthealth 一起完成了 DNA
                生命密码的探秘之旅，我们致力于将最前沿的生命科学技术带入您的生活中。我们的使命是通过深入研究
                DNA
                甲基化，为您提供个性化的健康解决方案，帮助您实现高质量的生活。
              </div>
              <div style="text-indent: 52px">
                在探索生命奥秘的旅程中，科学家们发现了一种调控基因表达的精细机制——DNA甲基化。这种生物化学过程在细胞的每个角落悄然发生，它不仅守护着基因组的稳定，更是连接遗传与环境的桥梁。DNA
                甲基化是一种表观遗传修饰，涉及 在 DNA
                分子的胞嘧啶上添加甲基化团。这种修饰能够调节基因的活性，而不影响其序列。甲基化水平的变化是细胞对环境
                变化作出反应的一种方式，它在胚胎发育、细胞分化、X
                染色体失活等生物学过程中发挥着关键作用。
              </div>
              <div>衰老的印记：甲基化与年龄</div>
              <div style="text-indent: 52px">
                随着年龄的增长，我们的 DNA
                甲基化模式也会发生改变。科学家们发现，某些基因的甲基化水平会随着时间的流逝而
                增加或减少，这些变化可能与衰老相关的生物学过程有关。例如，某些基因的高甲基化状态可能与细胞老化和组织功能障碍
                有关。
              </div>
              <div>疾病的信号：甲基化与健康</div>
              <div style="text-indent: 52px">
                DNA
                甲基化水平的异常变化与多种疾病的发生发展有关。在癌症中，肿瘤抑制基因的甲基化沉默可能导致肿瘤的发生。
                在神经退行性疾病中，特定基因的甲基化改变可能影响神经细胞的功能。此外，心血管疾病、糖尿病等慢性疾病也与甲基化
                状态的改变有关。
              </div>
              <div>生活方式的烙印：甲基化与习惯</div>
              <div style="text-indent: 52px">
                我们的生活方式，包括饮食、运动、睡眠和压力水平，都可能影响 DNA
                的甲基化。例如，健康的饮食和规律的锻炼可
                以改善甲基化模式，从而降低某些疾病的风险。相反，不良的生活方式，如吸烟和过度饮酒，可能会导致有害的甲基化变化。
              </div>
              <div style="color: #7eaa55; font-size: 40px; font-weight: 500">
                特别礼物，免费获取
              </div>
              <div style="color: #7eaa55">
                作为我们对您健康之旅的支持，PredictHealth
                很荣幸地邀请您免费获取一份自己的 DNA 甲基化数据。这份特别的礼物
                不仅是一种科学探索，更是 PredictHealth 对您健康承诺的体现。
              </div>
            </div>
          </div>
          <div class="reportOtherPage reportPage need">
            <div class="pageTitle" style="color: #7eaa55">
              全基因组建库和测序质量报告
            </div>
            <div class="qjyzjkhcxzlbg">
              <div class="" style="color: #7eaa55; font-size: 40px">
                以下是您此次检测数据：
              </div>
              <div>测序平台：illumina NovaSeq 6000</div>
              <div>
                Mapped Reads 百分比: <span class="color7eaa55">100.00%</span>
              </div>
              <div class="desc">
                这表示所有测序得到的 reads 都被成功地映射到了参考基因组上
              </div>
              <div>Average depth: <span class="color7eaa55">1.43</span></div>
              <div class="desc">
                平均覆盖深度为 1.43，意味着每个基因组位置平均被测序了 1.43 次。
              </div>
              <div>Coverage</div>
              <div>
                =0x: <span class="color7eaa55">42.90%</span>
                <span class="desc">覆盖深度为 0 的区域占 42.90%。</span>
              </div>
              <div>
                =4x: <span class="color7eaa55">8.22%</span>
                <span class="desc">覆盖深度为 4 的区域占 8.22%。</span>
              </div>
              <div>
                =10x: <span class="color7eaa55">2.02%</span>
                <span class="desc">覆盖深度为 10 的区域占 2.02%。</span>
              </div>
              <div>
                Properly paired 百分比: <span class="color7eaa55">96.35%</span>
              </div>
              <div class="desc">
                这表示 96.35%的测序 reads 是正确配对的，即它们是从一个 DNA
                片段的两端测序得到的。
              </div>
              <div class="blob">
                MapQuality above cutoff reads 百分比:
                <span class="color7eaa55">100.00%</span>
              </div>
              <div class="desc">
                所有测序 reads
                的映射质量都高于设定的阈值，这表明测序数据的质量非常好。
              </div>
              <div class="blob">
                Reads：<span class="color7eaa55">5.333×106</span>
              </div>
              <div class="desc">
                测序过程中总共产生了大约 5.333 百万个 reads。这里的 "reads"
                指的是测序过程中得到的 DNA 序列片段。
              </div>
            </div>
          </div>
          <div class="reportTwoPage reportPage">
            <div class="pageTitle">您的衰老时钟报告</div>
            <div class="desc">
              根据您的全基因组DNA甲基化数据，我们选取5324个
              CPGs构建的DNA生物学年龄计算出为
              <span class="age"> {{ reportInfo.dnaBiologicalAge }} </span>
              ，比您的实际年龄{{
                reportInfo.age < reportInfo.dnaBiologicalAge ? "大" : "小"
              }}
              <span class="age">{{
                Math.abs(reportInfo.age - reportInfo.dnaBiologicalAge)
              }}</span>
              岁。
            </div>
            <div class="schedule">
              <div
                class="age1Icon"
                :style="{ left: reportInfo.dnaBiologicalAge * 11 - 50 + 'px' }"
              >
                <div class="ageTxt">
                  <img
                    class="ageTxtIcon"
                    src="https://admin.predicthealth.cn/reportImg/EPISreport2DNA.jpg"
                  />
                  <span>DNA生物学年龄</span>
                </div>
                <img
                  src="https://admin.predicthealth.cn/reportImg/EPISreport2DNAAge.jpg"
                  class="icon"
                />
                <div class="ageVal">{{ reportInfo.dnaBiologicalAge }}</div>
              </div>
              <img
                class="status"
                src="https://admin.predicthealth.cn/reportImg/EPISreport2Status.jpg"
              />
              <div class="line">
                <div class="bg"></div>
                <div
                  class="age1Active"
                  :style="{
                    left: reportInfo.dnaBiologicalAge * 11 + 'px',
                  }"
                ></div>
                <div
                  class="age2Active"
                  :style="{ left: reportInfo.age * 11 + 'px' }"
                ></div>
              </div>
              <div class="txt">
                <div class="start">0</div>
                <div class="end">100</div>
              </div>
              <div
                class="age2Icon"
                :style="{ left: reportInfo.age * 11 - 6 + 'px' }"
              >
                <div class="ageVal">{{ reportInfo.age }}</div>
                <img
                  src="https://admin.predicthealth.cn/reportImg/EPISreport2ActualAge.jpg"
                  class="icon"
                />
                <div class="ageTxt">实际年龄</div>
              </div>
            </div>
            <div class="proportionAndStage">
              <div class="proportion">
                <div class="title">
                  您的DNA生物学年龄比
                  <span>{{ reportInfo.dnaBiologicalAgeRatio * 10 * 10 }}%</span>
                  同年龄人群更年轻。
                </div>
                <div class="list">
                  <div class="item" v-for="i in 100" :key="i">
                    <img
                      src="https://admin.predicthealth.cn/reportImg/EPISreport2crowd1.jpg"
                      class="crowd"
                      v-if="i < reportInfo.dnaBiologicalAgeRatio * 10 * 10"
                    />
                    <img
                      src="https://admin.predicthealth.cn/reportImg/EPISreport2crowd2.jpg"
                      class="crowd"
                      v-if="i == reportInfo.dnaBiologicalAgeRatio * 10 * 10"
                    />
                    <img
                      src="https://admin.predicthealth.cn/reportImg/EPISreport2ArrowBottom.jpg"
                      class="arrow"
                      v-if="i == reportInfo.dnaBiologicalAgeRatio * 10 * 10"
                    />
                    <img
                      src="https://admin.predicthealth.cn/reportImg/EPISreport2crowd3.jpg"
                      class="crowd"
                      v-if="i > reportInfo.dnaBiologicalAgeRatio * 10 * 10"
                    />
                  </div>
                </div>
              </div>
              <div class="stage">
                <div class="title">
                  您的身体跟数据库的人群相比处于
                  <span>{{
                    reportInfo.dnaBiologicalAge > reportInfo.age
                      ? "加速衰老"
                      : reportInfo.dnaBiologicalAge < reportInfo.age
                      ? "缓慢衰老阶段"
                      : "正常衰老"
                  }}</span>
                  。
                </div>
                <img
                  src="https://admin.predicthealth.cn/reportImg/EPISreport2database.jpg"
                  class="database"
                />
              </div>
            </div>
            <div class="report2Desc">
              人体机能随年龄增长而逐渐退化是一个自然而不可逆的衰老过程。衰老是一个渐进性的过程,涉及多种复杂的生物学机制。例如细胞凋亡、氧化应激、染色体端粒的缩短、蛋白质错误折
              叠等都是导致衰老的重要因素。衰老这一过程并非一成不变,它会受到多种内在和外在因素的影响而加速或延缓。我们一直在探索延缓衰老的方法,旨在改善生活质量。那么衰老可以被衡
              量吗?近年来,表观遗传学的研究为探索衰老本质提供了新的视角。表观遗传修饰如DNA甲基化、组蛋白修饰等,虽不改变DNA序列,但可影响基因的表达调控。其中,DNA甲基化就像是基
              因表达的"开关",它的模式会随年龄增长和环境变化而发生动态改变,这些改变与细胞和组织的衰老过程密切相关。通过全基因组DNA甲基化分析,我们的科学家们在上百万个甲基化位点中
              识别出一批随年龄增长而甲基化水平发生变化的特定位点,从而构建了表观遗传衰老时钟(EpiS
              Aging
              Clock)模型。该模型不仅可以评估个体的衰老速度,在临床研究中还可评估抗衰老干预
              措施的效果。
            </div>
          </div>
          <div class="reportThreePage reportPage">
            <div class="pageTitle">
              {{ reportInfo.sex == "男" ? "男" : "女" }}性衰老时钟
            </div>
            <div class="title">
              您的DNA生物学年龄已经比
              <span :style="{ color: reportInfo.sex == '男' ? '#264596' : '' }"
                >{{ reportInfo.dnaBiologicalAgeRatio * 10 * 10 }}%</span
              >
              同年龄段{{ reportInfo.sex == "男" ? "男" : "女" }}性年轻。
            </div>
            <div class="proportionContent">
              <div class="flex">
                <div class="canvasBox">
                  <canvas id="circleCanvas" width="262" height="262"></canvas>
                  <img
                    class="wumanHeadImg"
                    src="https://admin.predicthealth.cn/reportImg/EPISreportwumanHeadImg.png"
                    v-if="reportInfo.sex == '女'"
                  />
                  <img
                    v-else
                    class="wumanHeadImg"
                    src="https://admin.predicthealth.cn/reportImg/EPISreportmanHeadImg.png"
                  />
                </div>
                <div>
                  <div class="desc">
                    研究表明，女性的平均寿命通常比男性长。这部分原因可能与生物学年
                    龄的差异有关，女性的生物学老化过程可能较男性缓慢。女性的染色体
                    末端（端粒）往往比男性的长。端粒的长度被认为是细胞老化和生物学
                    年龄的一个标志。男性和女性在生物学年龄的差异是多方面的生物、社
                    会、心理和环境因素共同作用的结果。
                  </div>
                  <div class="ps">
                    参考第7页了解更多关于性别特异的生物年龄差异。
                  </div>
                </div>
              </div>
            </div>
            <div class="pageTitle" style="color: #6daad7">衰老速率指数</div>
            <div
              class="title"
              style="text-align: left; width: 860px; margin: 0 auto"
            >
              您的生物学衰老指数为
              <span style="color: #6daad7">{{ reportInfo.agingIndex }}</span>
              。<br />
              生物学衰老指数小于1意味着衰老减缓，大于1意味着衰老加速。
            </div>
            <div class="distributionMapOfAgingRate">
              <div class="text">
                <div class="title1">衰老速率的分布图</div>
                <div class="desc1">
                  图例显示，数据库中{{
                    reportInfo.agingIndexProportion
                  }}的人和您有相关的衰老速率。此<br />
                  分布图是实时计算的，随着数据的增加结果可能会有变化
                </div>
              </div>
              <div class="echart">
                <img
                  src="https://admin.predicthealth.cn/reportImg/EPISreportagingRateEchartBg.png"
                  class="bg"
                />
                <div class="list">
                  <div
                    :class="{
                      item: true,
                      active: index == reportInfo.agingIndexIndex,
                    }"
                    v-for="(item, index) in tagingRateList"
                    :key="index"
                    :style="{
                      height: item.height + 'px',
                      left: index * 20 + 16 + 'px',
                    }"
                  >
                    <div
                      class="activeTxt"
                      v-if="index == reportInfo.agingIndexIndex"
                    >
                      <div class="txt">Your Rate</div>
                      <img
                        src="https://admin.predicthealth.cn/reportImg/EPISreportArrowBottom58acdb.png"
                        class="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="reportFourPage reportPage" style="height: 2020px">
            <div class="pageTitle" style="color: #6daad7">过往数据比较</div>
            <div class="dataComparisonEchartBox">
              <div class="labels">
                <div>
                  <div class="line"></div>
                  <div class="txt">Calendar Age</div>
                </div>
                <div>
                  <div class="line"></div>
                  <div class="txt">DNA Age</div>
                </div>
              </div>
              <div id="dataComparisonEchart"></div>
            </div>
            <div class="dataComparisonTxt">
              为了检测您的衰老时钟和抗衰老的效果，推荐每6-12个月检测一次。
            </div>
            <div class="pageTitle" style="color: #259c22">表观遗传代谢指数</div>
            <div class="metabolicIndexTxt">
              特定的DNA甲基化启动子与代谢异常相关。DNA甲基化在调节基因表达和代谢途径<br />
              中的作用，以及代谢异常如肥胖、糖尿病和心疾病与DNA甲基化水平的强相关。
            </div>
            <div class="title" style="width: 860px; margin: 0 auto">
              您的表观遗传代谢指数为
              <span style="color: #e07071">{{
                reportInfo.metabolismIndex
              }}</span>
              ，{{
                reportInfo.metabolismIndex < 6.3
                  ? "无代谢异常风险"
                  : "有代谢异常风险"
              }}。
            </div>
            <div class="metabolicIndexSchedule">
              <div class="rotundity">
                <div class="border"></div>
              </div>
              <div class="schedule">
                <div class="line"></div>
                <div class="number">
                  <div>0</div>
                  <div class="normal">6.3</div>
                  <div>20</div>
                </div>
                <div
                  class="valueIndex"
                  :style="{
                    left: 50 + 29 * reportInfo.metabolismIndex + 'px',
                    'background-color':
                      reportInfo.metabolismIndex > 6.3 ? 'red' : '#2fa4d8',
                  }"
                ></div>
                <div class="normalIndex"></div>
              </div>
            </div>
            <div class="distributionMapOfAgingRate">
              <div class="text">
                <div class="title1">表观遗传代谢指数的分布图</div>
                <div class="desc1">
                  图例显示，数据库中22%的人和您有相同的表观遗传代谢指数。此分布图是实时计算的，随着数据的增加结果可能会有变化。
                </div>
                <div
                  style="font-size: 18px; margin-top: 20px; text-align: justify"
                >
                  研究表明，肥胖和糖尿病患者的DNA基化模式与正常代谢状态下的个体存
                  在显著差异。心血管疾病者的某些基因组区域存在异常的甲基化模式，这些区域可能涉及到心血管健康相关基因的表达调节。环境因素也可能影响DNA甲基化水平，从而影响代谢功能。环境暴露如饮食习惯、化学物质暴露、生活方式等因素都与DNA甲基化的变化相关。
                </div>
              </div>
              <div class="echart">
                <img
                  src="https://admin.predicthealth.cn/reportImg/EPISreportagingRateEchartBg2.jpg"
                  class="bg"
                />
                <div class="list">
                  <div
                    :class="{
                      item: true,
                      active1: index == 3,
                      active2: index == 4,
                    }"
                    v-for="(item, index) in tagingRateList2"
                    :key="index"
                    :style="{
                      height: item.height + 'px',
                      left: index * 31 + 16 + 'px',
                    }"
                  >
                    <div class="activeTxt activeTxt1" v-if="index == 3">
                      <div class="txt" style="position: relative; z-index: 1">
                        Your Score
                      </div>
                      <img
                        src="https://admin.predicthealth.cn/reportImg/EPISreportArrowBottom58acdb.png"
                        class="arrow"
                      />
                    </div>
                    <div class="activeTxt activeTxt2" v-if="index == 4">
                      <div class="txt" style="position: relative; z-index: 1">
                        Reference Point
                      </div>
                      <img
                        src="https://admin.predicthealth.cn/reportImg/EPISreportArrowBottom58acdb.png"
                        class="arrow"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="reportOtherPage" style="height: 4034px">
            <div class="pageTitle" style="color: #459d2f">
              EPIS衰老时钟背后的科学
            </div>
            <div style="text-align: center">什么是表观遗传学?</div>
            <div>
              表观遗传学是一个来自生物学领域的术语。它指的是在不改变基因的主要DNA序列的情况下发生的基因表达变化。在发育过程中，表观遗传机制以高度调控的方式打开或关闭基因，以赋予和维持每个细胞的身份。例如，你的每一个细胞都包含完全相同的基因序列，然而肝细胞却必须知道自己是肝细胞，心脏细胞也必须知道自己是心脏细胞，以此类推。多亏了表观遗传学，你才拥有了复杂的器官执行维持生命所必需的各种功能的系统。
            </div>
            <img
              class="epigenetics"
              style="width: 690px"
              src="https://admin.predicthealth.cn/reportImg/epigenetics.png"
            />
            <div style="font-weight: 700">为什么这很重要?</div>
            <div>
              <div>
                表观遗传对基因表达的控制贯穿我们的一生，它直接受到我们不断变化的外部环境的影响。重要的是，虽然构成我们基因的DNA序列是静态的，不会改变，但在我们的一生中，我们的表观遗传学会发生变化。通过多年的科学研究，我们知道生活方式因素，包括运动、饮食、压力、吸烟或饮酒，都会影响表观遗传能力，从而控制我们基因的DNA。在这种情况下，表观遗传学真正定义了一个过程，在这个过程中，我们自己的个人经历，或者我们自己的生活史，直接写在我们的DNA上。
              </div>
              <br />
              <div>
                至关重要的是，越来越多的科学证据表明，表观遗传机制在调节人类健康方面发挥着至关重要的作用，包括生命早期阶段的生长和发育、一生中的日常代谢，以及随着年龄的增长，癌症等疾病的进展。你可以对你的表观遗传产生巨大的影响，从而影响你如何变老。你可以通过选择健康的生活方式来产生积极的影响，也可以产生消极的影响，因为许多不健康的生活方式都与更高的衰老率相关。
              </div>
            </div>
            <img
              class="epigenetics"
              style="width: 400px; margin-top: 10px"
              src="https://admin.predicthealth.cn/reportImg/DNAMethylation.png"
            />
            <div>
              DNA甲基化是研究最多的表观遗传修饰。这一生物过程在DNA分子中加入甲基(Me)，从而对基因表达产生影响。
            </div>
            <img
              class="epigenetics"
              style="width: 340px"
              src="https://admin.predicthealth.cn/reportImg/DNAMethylation2.png"
            />
            <img
              class="epigenetics"
              style="width: 340px"
              src="https://admin.predicthealth.cn/reportImg/DNAMethylation3.png"
            />
            <div style="font-weight: 700">什么是生物年龄?</div>
            <div>
              生物学年龄是一个帮助量化我们体内细胞年龄的概念。它可以根据与衰老和衰退相关的各种性质和生物标志物来计算。表观遗传生物标记，如DNA甲基化，已经被证明可以准确地测量生物年龄。
            </div>
            <br />
            <div>
              此外，最近的研究甚至证明了不同的疾病或综合征对你的生物学年龄的有害影响。加州大学洛杉矶分校教授史蒂夫·霍瓦特博士开创了基于DNA甲基化的生物年龄预测领域，创造了一种被称为霍瓦特时钟的表观遗传衰老时钟，它使用了353个可以发生甲基化的DNA位点(通常称为CpG位点)。霍瓦特使用了大约8000人的数据
            </div>
            <br />
            <div>
              在2013年的一项研究中，研究对象展示了一种现代而准确的测量生物学年龄的方法。那项研究被引用了1500多次，仍然是表观遗传学测量生物学年龄的标准。
            </div>
            <img
              class="epigenetics"
              style="width: 480px"
              src="https://admin.predicthealth.cn/reportImg/BiologicalAge.png"
            />
          </div>
          <div class="reportOtherPage" style="height: 4034px">
            <div class="pageTitle" style="color: #459d2f">
              与衰老密切相关的甲基化marker
            </div>
            <div class="xiaosi">
              <div class="xiaosititle">p16^INK4a</div>
              <div class="xiaosidesc">
                ①是一种细胞周期抑制蛋白，属于INK4家族，它通过抑制CDK4和CDK6的活性来阻止细胞周期从G1期进入S期，<br />
                ②随着年龄的增长，p16^INK4a基因的CpG岛区域可能会出现高甲基化现象，特别是在那些经历复制性衰老的细胞中。这种高甲基化可以导致基因的沉默，减少p16^INK4a蛋白的表达。<br />
                ③p16^INK4a的甲基化和表达下降还与多种与年龄相关的疾病有关，包括心血管疾病、神经退行性疾病和各种类型的癌症。
              </div>
              <div class="xiaosititle">LINE-1 (L1)</div>
              <div class="xiaosidesc">
                ①LINE-1（Long Interspersed
                Element-1）是哺乳动物基因组中的一种长散布核元件，属于反转录转座子的一种。它构成了人类基因组的大约17%，并且由于其能够通过复制和粘贴机制在基因组中移动，因此在进化上具有重要意义。<br />
                ②随着年龄的增长，LINE-1的甲基化水平可能会下降，这可能导致它们的转座活性增加，从而促进衰老过程和增加神经退行性疾病等年龄相关疾病的风险<br />
                ③在某些神经退行性疾病，如阿尔茨海默病中，LINE-1的甲基化水平下降可能与疾病的发病机制有关。转座元件的激活可能导致神经系统功能障碍
              </div>
              <div class="xiaosititle">IGF2 DMR</div>
              <div class="xiaosidesc">
                ①IGF2（胰岛素样生长因子2）是一种在细胞生长、增殖和代谢中起关键作用的蛋白质。IGF2基因的表达受到严格调控，IGF2
                DMR位于IGF2基因的上游区域，是一个 CpG
                岛，其甲基化状态对IGF2基因的表达具有重要影响。<br />
                ②IGF2
                DMR的甲基化改变与多种年龄相关疾病有关，包括2型糖尿病、心血管疾病和某些类型的癌症。这些疾病可能与IGF2信号通路的异常激活有关<br />
                ③随着年龄的增长，IGF2
                DMR的甲基化模式可能会发生改变。一些研究发现，老年人的IGF2
                DMR甲基化水平可能低于年轻人，这可能与衰老过程中细胞功能下降有关
              </div>

              <div class="xiaosititle">CDKN2A</div>
              <div class="xiaosidesc">
                ①CDKN2A（细胞周期依赖性激酶抑制剂2A）是一个重要的肿瘤抑制基因，它编码两种蛋白质：p16^INK4a和p14^ARF。这两种蛋白质都参与调控细胞周期，防止异常细胞不受控制地增殖。<br />
                ②随着年龄的增长，CDKN2A的甲基化模式可能会发生改变，这可能与衰老过程中细胞功能下降和癌症风险增加有关<br />
                ③CDKN2A的甲基化状态反映了基因表达的表观遗传调控。了解CDKN2A的甲基化模式如何随年龄变化，有助于揭示衰老和癌症发展过程中表观遗传调控的变化
              </div>

              <div class="xiaosititle">TERT</div>
              <div class="xiaosidesc">
                ①TERT（端粒酶逆转录酶）是端粒酶的催化亚基，它在维持端粒长度和细胞的永生化中起着关键作用。端粒是位于染色体末端的DNA-蛋白复合体，它们保护染色体不受损伤和融合。在细胞分裂过程中，端粒会逐渐缩短，当端粒长度低于某个临界值时，细胞会进入衰老状态或经历凋亡。<br />

                ②随着年龄的增长，TERT的甲基化模式可能会发生改变，这可能与端粒缩短和细胞衰老有关<br />
                <br />
                <br />
                <br />
                ③随着年龄的增长，TERT基因的甲基化模式可能变得更加异质性，意味着不同细胞或组织中的甲基化状态可能有所不同。这种异质性可能反映了细胞和组织的不同衰老速率。
              </div>

              <div class="xiaosititle">RASSF1A</div>
              <div class="xiaosidesc">
                ①是一种肿瘤抑制基因，它在调控细胞增殖、凋亡和细胞周期进程中起着重要作用。RASSF1A基因的甲基化改变，尤其是在其启动子区域，<br />
                ②随着年龄的增长，RASSF1A的甲基化水平可能会增加，这可能与衰老相关的癌症风险增加有关。<br />
                ③RASSF1A的甲基化与多种癌症的发生有关，包括肺癌、乳腺癌、神经胶质瘤等。这种甲基化可能是癌症早期事件之一，与肿瘤的发展和进展有关，RASSF1A的甲基化可能受到遗传背景和环境因素的影响，包括生活方式和暴露于致癌物质。
              </div>
              <div class="xiaosititle">GSTP1</div>
              <div class="xiaosidesc">
                ①GSTP1（谷胱甘肽S-转移酶P1）是一种重要的解毒酶，参与清除体内的自由基和代谢废物，包括某些药物和致癌物质。GSTP1的活性可以影响个体对环境毒素的敏感性，因此它在癌症风险中起着潜在作用，<br />
                ②随着年龄的增长，细胞的甲基化模式可能会发生变化，包括GSTP1的甲基化水平，这可能与衰老过程中细胞功能的下降有关。<br />
                ③GSTP1在抵抗氧化应激中起作用，其甲基化导致的活性下降可能增加氧化应激，这是衰老的一个特征，GSTP1的甲基化可能受到遗传背景和环境因素的影响，包括生活方式、饮食和职业暴露于致癌物质
              </div>
            </div>
          </div>
          <div class="reportOtherPage" style="height: 2000px">
            <div class="pageTitle" style="color: #459d2f">
              EPIS衰老时钟结果解读
            </div>
            <div>女性相对于男性的衰老速率低，这意味女性比男性寿命更长。</div>
            <img src="https://admin.predicthealth.cn/reportImg/slszjjjd1.png" />
            <div style="font-size: 14px">
              上图显示了性别特异性的生物学年龄趋势。
            </div>
            <div>不同职业压力影响老龄化率</div>
            <img src="https://admin.predicthealth.cn/reportImg/slszjjjd2.png" />
            <div style="font-size: 14px">
              上图列出了平均老龄化率从左到右递减的职业。压力相对较高的职业显示出更高的衰老速率。
            </div>
          </div>
          <div class="reportOtherPage" style="height: 2000px">
            <div class="pageTitle" style="color: #459d2f">
              EPIS衰老时钟的改善计划
            </div>
            <div class="factorsToAvoidTitle" style="background-color: #b60500">
              需要避免的因素
            </div>
            <div class="factorsToAvoidList">
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/factorsToAvoidList1.png"
                  class="icon"
                />
                <div class="right">
                  <div class="itemTitle">减少慢性压力</div>
                  <div class="itemDesc">
                    练习冥想和瑜伽等正念减压方法。这是改善DNA生物学年龄的关键，研究已经证明压力会加速表观遗传衰老。
                  </div>
                </div>
              </div>
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/factorsToAvoidList2.png"
                  class="icon"
                />
                <div class="right">
                  <div class="itemTitle">减少接触烟草和烟雾</div>
                  <div class="itemDesc">
                    研究表明，减少烟雾暴露(包括烟草)和长期暴露于野火有充分益处。益处包括减少心血管危险因素、预期寿命和改善生物学年龄。
                  </div>
                </div>
              </div>
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/factorsToAvoidList3.png"
                  class="icon"
                />
                <div class="right">
                  <div class="itemTitle">减少长期空气污染的暴露</div>
                  <div class="itemDesc">
                    长期暴露于环境空气污染与许多与年龄相关的不良结局相关，包括但不限于心血管疾病、认知功能受损、癌症、代谢结局和死亡率。上述所有与空气污染相关的结局也均与衰老相关。研究探索了这些关联的基础生物学，并将空气污染暴露与表观遗传改变联系起来。
                  </div>
                </div>
              </div>
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/factorsToAvoidList4.png"
                  class="icon"
                />
                <div class="right">
                  <div class="itemTitle">减少对环境毒素的暴露</div>
                  <div class="itemDesc">
                    环境毒素包括电离辐射和内分泌干扰物。暴露于电离辐射会在人体组织中产生氧源性自由基。而内分泌干扰物(如BPA和邻苯二甲酸酯)是激素活性物质/化学物质，可干扰人体内激素的自然循环。当被人体吸收后，内分泌干扰物会降低或增加正常的激素水平，模拟人体的天然激素，或改变激素的自然生成。在加工食品、塑料和化妆品中发现了内分泌干扰物。
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="reportOtherPage" style="height: 2067px">
            <div style="height: 80px"></div>
            <div class="howToImprove" style="background-color: #459d2f">
              如何改善
            </div>
            <div class="howToImproveList">
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/howToImproveList1.png"
                  class="icon"
                />
                <div class="itemTitle">用心地锻炼</div>
                <div class="itemDesc">
                  充分的研究已经证明了每天锻炼的好处——好处包括：心血管健康，增加流动性，降低癌症风险。然而，过多的运动会加速生物学老化。
                </div>
              </div>
              <div class="item" style="padding-left: 50px; padding-right: 50px">
                <img
                  src="https://admin.predicthealth.cn/reportImg/howToImproveList2.png"
                  class="icon"
                />
                <div class="itemTitle">保持充足的睡眠</div>
                <div class="itemDesc">
                  越来越多的证据表明，睡眠不足和睡眠障碍是疾病早期发病和生存期缩短的促发因素。
                </div>
              </div>
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/howToImproveList3.png"
                  class="icon"
                />
                <div class="itemTitle" style="line-height: 60px">
                  食用有机食品，尽量减少加工食品精制糖
                </div>
                <div class="itemDesc">
                  有机、完整的饮食已被证明不仅能最大限度地提高我们细胞的抗氧化潜力，而且还能消除进入我们体内的有害致癌物质和老年毒素(老化毒素)
                </div>
              </div>
            </div>
          </div>
          <div class="reportOtherPage" style="height: 2000px">
            <div class="pageTitle">生活方式调节改善EPIS衰老时钟</div>
            <img src="https://admin.predicthealth.cn/reportImg/slsz1.png" />
            <div>
              根据一项研究统计，63%的参与者在实施健康生活方式干预后变得更年轻，积极地延长了他们的健康寿命和生活质量。值得注意的是，相对容易实现的生活方式行为的改变，可显著减缓生物衰老生物标志物的变化，从而降低罹患年龄相关疾病的风险，并提高老年生活质量。
            </div>
            <img src="https://admin.predicthealth.cn/reportImg/slsz2.png" />
            <div>
              整个研究在6个月的时间内结合多种生活方式的改变，包括每天步行5-6公里，健康的饮食，包括大量的水果，蔬菜，鱼，海鲜，坚果，全谷物面包，有限的奶酪，酸奶和糖果获胜者通过间歇性断食过渡到生酮饮食，还开始喝抹茶和可可，以获得营养。
            </div>
          </div>
          <div class="reportOtherPage" style="height: 2067px">
            <div class="pageTitle">表观遗传代谢指数背后的科学</div>

            <div>
              快速积累的实验、临床和流行病学证据表明，表观遗传因素，特别是DNA甲基化，是代谢特征的关键因素。DNA甲基化是静态和动态的，它可以响应外部和内部环境的提示。环境影响已被确定为代谢性疾病的主要危险因素。越来越多的临床研究发现，甲基化特征与包括BMI、血压和血脂水平在内的几种心血管代谢特征相关。我们利用科学证明的DNA甲基化特征来构建外代谢指数。
            </div>
            <br />
            <div>采用了一个星型系统来表示每个分析的DNA甲基化位点的科学证据</div>
            <div class="table">
              <div class="tableList">
                <div class="item">基因</div>
                <div class="item">ABCG1</div>
                <div class="item">CPTIA</div>
                <div class="item">PHGDH</div>
                <div class="item">SREBF1</div>
                <div class="item">TXNIP</div>
                <div class="item">HIF3A</div>
                <div class="item">KLF13</div>
                <div class="item">MYO5C</div>
                <div class="item">“SARS”</div>
                <div class="item">SLC7A11</div>
                <div class="item">SOCS3</div>
                <div class="item">USP22*</div>
              </div>
              <div class="tableList">
                <div class="item">证据水平</div>
                <div class="item level2">***</div>
                <div class="item level1">****</div>
                <div class="item level1">****</div>
                <div class="item level1">****</div>
                <div class="item level1">****</div>
                <div class="item level2">***</div>
                <div class="item level2">***</div>
                <div class="item level2">***</div>
                <div class="item level2">***</div>
                <div class="item level2">***</div>
                <div class="item level2">***</div>
                <div class="item level2">***</div>
              </div>
              <div class="tableDesc">
                <div>
                  <span class="level1"> ****</span
                  >有力的科学证据。一项超过1000人的强有力的研究，以及另一项额外的研究显示了相同的结果。
                </div>
                <div>
                  <span class="level2">***</span
                  >有力的科学证据。一项研究，样本量约为500人或更多，有或没有重复研究。
                </div>
                <div>
                  <span class="level3">**</span
                  >潜在的科学证据。多个小样本量发表的研究，有一些共识。
                </div>
                <div><span class="level4">*</span>科学意义未知。</div>
              </div>
            </div>
            <div>
              很多基因在调节新陈代谢，可以通过调节营养摄入以及食物和营养优化你的基因表达。
            </div>
          </div>

          <div class="reportOtherPage" style="height: 1967px">
            <div class="pageTitle">认识表观遗传代谢指数</div>
            <div>
              外显代谢指数测试是用来测量代谢紊乱的易感性，这是一个影响整体生物年龄的因素。在我们的数据库中，90%没有已知代谢紊乱的受试者的指数低于6.3。
            </div>
            <div
              class="howToImprove"
              style="background-color: #1c93ce; width: 260px"
            >
              糖尿病
            </div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/Diabetes.png"
            />

            <div>
              在我们目前的人群数据库中，非糖尿病、糖尿病前期和2型糖尿病患者的外显代谢指数呈上升趋势。
            </div>
            <div
              class="howToImprove"
              style="background-color: #1c93ce; width: 260px"
            >
              重量
            </div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/weight.png"
            />
            <div>
              根据我国现有的人口数据库，观察到表观遗传代谢指数从正常到肥胖的增加趋势。体重过轻的个体也表现出表观遗传代谢指数的适度增加。
            </div>
          </div>

          <div class="reportOtherPage" style="height: 2067px">
            <div class="pageTitle">表观遗传代谢指数改善计划</div>
            <div style="text-align: center; color: #459d2f">营养</div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/nutrition.png"
            />
            <div style="margin-top: 30px">
              几十年来，营养调节衰老的过程已经很明显了。关于营养对衰老的影响，最早的明确证据来自对啮齿动物的研究，这些研究表明，限制饮食(限制能量摄入，同时确保充足的营养供应)可以延长寿命，并减少(或延迟)与衰老相关的疾病的发生。
            </div>
            <div>
              饮食限制现已成为一种非常成熟的生活方式干预方法，可延长包括人类在内的许多物种的健康寿命。一项值得关注的研究表明，成年期接受30%饮食限制的猴子更瘦，年龄相关疾病的负担更低。此外，一项CALERIE(减少能量摄入的长期影响的综合评估，Comprehensive
              Assessment of Long-Term Effects of Reducing Intake of
              Energy)随机试验表明，在长期限制热量摄入的非肥胖成人中观察到生物学衰老减缓。
            </div>
            <div
              style="
                text-align: center;
                color: #459d2f;
                font-weight: 700;
                font-size: 30px;
                margin-top: 30px;
              "
            >
              关于营养的两个重要因素:
            </div>
            <div class="importantFactor">
              <div class="none"></div>
              <div class="item" style="margin-right: 100px">
                <img
                  src="https://admin.predicthealth.cn/reportImg/importantFactor1.png"
                  class="icon"
                />
                <div class="txt">重量</div>
              </div>
              <div class="item">
                <img
                  src="https://admin.predicthealth.cn/reportImg/importantFactor2.png"
                  class="icon"
                />
                <div class="txt">数量</div>
              </div>
              <div class="none"></div>
            </div>
          </div>

          <div class="reportOtherPage" style="height: 4034px">
            <div class="pageTitle">控制食物的摄入量</div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/kzswdsrl.png"
            />
            <div class="dotList">
              <div class="item">
                <div class="dot"></div>
                <div class="txt">避免暴饮暴食。</div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  间歇性禁食已经成为一种专注于什么时候吃而不是吃什么的计划。这种方法需要交替进行长时间的断食和相对较短的进食时间窗。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  细嚼慢咽，吃饭时尽量不要做其他事情。当你没有分心的时候，你的大脑会在适当的时候发出满意的信号。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">根据你的身高和年龄保持健康的体重。</div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  健康的减肥只有通过监测持续的卡路里不足才能实现。
                </div>
              </div>
            </div>
            <div class="pageTitle">选择合适的食物</div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/xzhsdsw.png"
            />
            <div class="dotList">
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  尽可能多吃有机食品。采用以低血糖负荷、低饱和和反式脂肪、富含抗氧化剂的植物性食物为基础的饮食。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">用全谷物代替精制谷物。</div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  增加每日蔬菜和水果摄入量，每餐至少食用一份生蔬菜和一份熟蔬菜，至少食用2-3份水果。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  注重地中海式饮食。<br />
                  地中海饮食——一种饮食模式，其特征是乳制品、肉类和肉制品的摄入量低，鱼类的摄入量中至高，豆类、坚果、谷物的摄入量高，并且只使用特级初榨橄榄油作为调味和烹饪脂肪。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  避免加工食品和精制糖。蛋糕和甜点每周不应超过一次。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  吃零食的时候，每天抓两把坚果。坚果对你的腰围、长期健康都有好处，还有助于预防心脏病和糖尿病。
                </div>
              </div>
            </div>
            <br />
            <br />
            <div class="pageTitle">锻炼</div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/dl.png"
            />
            <div class="dotList">
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  每天运动。即使在繁忙的日子，也要每天进行20分钟的中等强度的步行。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  避免过度运动。过度锻炼和不锻炼一样有害。你最好的向导就是你身体的感觉，所以要听从你的身体。慢慢地建立你的锻炼计划。
                </div>
              </div>
            </div>

            <div class="pageTitle">保持健康的体重</div>
            <img
              class="imgMargin0Auto"
              src="https://admin.predicthealth.cn/reportImg/bcjkdtz.png"
            />
            <div class="dotList">
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  健康的饮食和锻炼都有助于获得和保持健康的体重。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  在必要的时候，热量不足是唯一的减肥方法。能量输出(基础代谢率和运动中消耗的热量)必须超过能量输入(我们吃的东西)。
                </div>
              </div>
              <div class="item">
                <div class="dot"></div>
                <div class="txt">
                  当试图达到一个健康的体重时，在你的饮食中保持蛋白质的摄入。减肥应该是由于脂肪的减少，而不是肌肉的减少。
                </div>
              </div>
            </div>
          </div>

          <div class="reportOtherPage" style="height: 6050px">
            <div class="pageTitle">表观遗传代谢指数相关基因介绍</div>

            <div class="xiaosi">
              <div class="xiaosititle">ABCG1 (ATP结合盒亚家族G成员1)</div>
              <div class="xiaosidesc">
                越来越多的证据表明，ABCG1在肥胖相关疾病风险中参与胆固醇代谢和炎症应激。ABCG1在脂肪组织中高表达，促进甘油三酯进入脂肪细胞。在肝脏和巨噬细胞中，可能发挥调节组织脂质水平的作用。<br />
                大量研究表明， ABCG1位点的DNA甲基化与以下因素正相关:
                <div class="xiaosidescList">
                  <div class="item">肥胖</div>
                  <div class="item">空腹胰岛素</div>
                  <div class="item">身体质量指数(BMI)</div>
                  <div class="item">患2型糖尿病(T2D)的风险</div>
                  <div class="item">甘油三酯(TG)水平</div>
                  <div class="item">高密度脂蛋白(HDL)水平</div>
                </div>
              </div>

              <div class="xiaosititle">CPT1A(肉碱棕榈酰基转移酶1A)</div>
              <div class="xiaosidesc">
                CPT1A是肉碱棕榈酰基转移酶1
                (CPT1)的三种亚型之一。CPT-1是一种参与调节线粒体脂肪酸氧化(mitochondrial
                fatty acid oxidation,
                FAO)的重要酶。CPT1A在肝脏中高度表达，在肝脏中启动其功能，因此有助于调节血清甘油三酯水平。<br />
                超过30项研究表明， CPT1A位点的DNA甲基化与以下相关:

                <div class="xiaosidescList">
                  <div class="item">ApoB脂蛋白的脂质浓度</div>
                  <div class="item">甘油三酯(TG)水平</div>
                  <div class="item">总血清甘油三酯(TG)</div>
                  <div class="item">2型糖尿病(T2D) / 2型糖尿病(T2DM)</div>
                  <div class="item">肝脏脂肪</div>
                  <div class="item">血压特征</div>
                </div>
              </div>
              <div class="xiaosititle">PHGDH</div>
              <div class="xiaosidesc">
                PHGDH是催化丝氨酸生物合成磷酸化途径的第一步和限速步骤的酶。许多研究需要在代谢和肥胖相关表型中对该酶进行检查。PHGDH在某些癌症中过度表达的证据进一步扩展了其代谢意义，因为它驱动代谢变化导致细胞快速生长。<br />
                研究表明， PHGDH位点的DNA甲基化与以下相关:
                <div class="xiaosidescList">
                  <div class="item">甘油三酯(TG)水平</div>
                  <div class="item">多不饱和脂肪酸(PUFAs)测定</div>
                  <div class="item">肥胖</div>
                  <div class="item">非酒精性脂肪性肝病(NAFLD)</div>
                  <div class="item">收缩压和舒张压</div>
                  <div class="item">体重指数(BMI)和腰围</div>
                </div>
              </div>
              <div class="xiaosititle">SREBF1(甾醇调节元件结合转录因子1)</div>
              <br />
              <br />
              <br />
              <div class="xiaosidesc">
                SREBF1结合低密度脂蛋白受体基因启动子区域的一个基序，激活其胆固醇代谢的转录。SREBF1甲基化可能作为血脂和心脏代谢功能障碍的潜在“多用途”生物标志物。<br />
                不同的队列研究发现，SREBF1位点的DNA甲基化与以下相关:

                <div class="xiaosidescList">
                  <div class="item">空腹血液总胆固醇水平</div>
                  <div class="item">肝脏甘油三酯(TG)水平</div>
                  <div class="item">体重指数(BMI)</div>
                  <div class="item">2型糖尿病(T2D)</div>
                  <div class="item">单不饱和脂肪酸的摄入</div>
                </div>
                该基因的甲基化可以作为2型糖尿病的早期生物标志物，通过测定脂肪酸代谢的早期功能障碍。
              </div>
              <div class="xiaosititle">硫氧还蛋白(TXNIP)</div>
              <div class="xiaosidesc">
                TXNIP已成为调节胰腺β细胞功能障碍和死亡的主要因子，而胰腺β细胞功能障碍和死亡是1型和2型糖尿病发病的关键过程。TXNIP还可能参与营养感知、能量代谢和细胞氧化还原调节。<br />
                多项研究表明，无论种族和社会差异如何，TXNIP位点的DNA甲基化与与以下相关:
                <div class="xiaosidescList">
                  <div class="item">空腹血糖水平</div>
                  <div class="item">糖化血红蛋白(HbA1c)水平</div>
                  <div class="item">2型糖尿病(T2D)发生率</div>
                  <div class="item">胰岛素敏感性</div>
                </div>
              </div>
              <div class="xiaosititle">缺氧诱导因子(HIF3A)</div>
              <div class="xiaosidesc">
                HIF3A属于缺氧诱导因子(hypoxia-inducible factors,
                hif)转录因子家族，主要调控细胞对缺氧的反应。HIF3A也可能参与糖代谢的调节，并作为脂肪细胞分化的加速器。<br />
                据报道，我们感兴趣的HIF3A位点的DNA甲基化与以下相关:
                <div class="xiaosidescList">
                  <div class="item">体重指数(BMI)</div>
                  <div class="item">腰围</div>
                  <div class="item">内脏脂肪组织厚度</div>
                </div>
              </div>
              <div class="xiaosititle">Myo5C(肌球蛋白VC)</div>
              <div class="xiaosidesc">
                MYO5C基因的DNA甲基化是一个有充分证据的肥胖相关标记。MYO5C在胰腺、前列腺等上皮和腺体组织中含量尤其丰富。MYO5C甲基化与血清c反应蛋白(CRP)水平相关，后者是慢性低度炎症的一个指标。<br />
                据报道， MYO5C位点的DNA甲基化与以下相关:
                <div class="xiaosidescList">
                  <div class="item">肥胖</div>
                  <div class="item">增加空腹血糖水平</div>
                  <div class="item">高密度脂蛋白(HDL)水平</div>
                  <div class="item">体重指数(BMI)</div>
                  <div class="item">血清c反应蛋白(CRP)</div>
                </div>
              </div>
              <div class="xiaosititle">SARS (Seryl-tRNA合成酶)</div>
              <div class="xiaosidesc">
                SARS编码一种蛋白质，该蛋白质催化l-丝氨酸转移到tRNA以合成蛋白质。外周血源性DNA
                CpG位点的差异甲基化（DMR）与以下相关:
                <br /><br /><br />
                <div class="xiaosidescList">
                  <div class="item">血甘油三酯(TG)水平</div>
                  <div class="item">体重指数(BMI)</div>
                  <div class="item">吸烟</div>
                  <div class="item">全因死亡率</div>
                </div>
              </div>
              <div class="xiaosititle">SLC7A11(溶质载波族7成员11)</div>
              <div class="xiaosidesc">
                SLC7A11编码调节抗氧化剂谷胱甘肽水平的半胱氨酸-谷氨酸抗转运蛋白，它是环境神经毒素β-甲胺-
                l
                -丙氨酸(BMAA)的已知靶点。研究确定SLC7A11基因可能是帕金森病的生物学靶点。
                据报道，SLC7A11位点的DNA甲基化与以下相关:

                <div class="xiaosidescList">
                  <div class="item">收缩压和舒张压</div>
                  <div class="item">肾脏和心脏的动脉硬化特征</div>
                  <div class="item">饮酒</div>
                </div>
              </div>
              <div class="xiaosititle">细胞因子信号传导抑制因子-3 SOCS3</div>
              <div class="xiaosidesc">
                SOCS3是一个很好的特征基因，参与免疫系统调节，如感染和自身免疫。习惯饮食质量与SOCS3
                DNA甲基化水平的差异有关。最近有报道称，SOCS3甲基化可调节累积应激对体重的影响。<br />
                据报道， SOCS3位点的DNA甲基化与以下相关:
                <div class="xiaosidescList">
                  <div class="item">肥胖</div>
                  <div class="item">多重代谢综合征(MetS)特征</div>
                  <div class="item">2型糖尿病(T2D)</div>
                  <div class="item">累积压力</div>
                </div>
              </div>
              <div class="xiaosititle">USP22(泛素特异性肽酶22)</div>
              <div class="xiaosidesc">
                已观察到USP22介导多种细胞过程，包括细胞增殖和细胞凋亡(细胞死亡)。USP22的异常表达与癌症预后不良和神经系统疾病有关。<br />
                据报道， USP22位点的DNA甲基化与以下相关:

                <div class="xiaosidescList">
                  <div class="item">身体质量指数(BMI)</div>
                  <div class="item">肥胖</div>
                </div>
              </div>
            </div>
          </div>
          <div class="reportOtherPage">
            <div class="pageTitle">参考文献</div>

            <div v-for="(item, index) in References" :key="index">
              <div v-if="index == 12"><br /><br /></div>
              <div v-if="index == 24"><br /><br /><br /><br /></div>
              <div v-if="index == 36"><br /><br /></div>
              <div v-if="index == 50"><br /><br /><br /></div>
              <div v-if="index == 61"><br /><br /><br /></div>
              <div v-if="index == 74"><br /><br /><br /></div>
              <div v-if="index == 87"><br /><br /><br /></div>
              <span>{{ index + 1 }}. </span>
              <span v-html="item"></span>
            </div>
          </div> -->
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { References } from "./EPISDEtails.js";
import { printPdf } from "@/utils/htmltopdf copy 2.js";
export default {
  data() {
    return {
      reportInfo: {},
      //   报告第三页柱状图高度
      tagingRateList: [
        {
          height: 4,
        },
        {
          height: 16,
        },
        {
          height: 60,
        },
        {
          height: 170,
        },
        {
          height: 260,
        },
        {
          height: 230,
        },
        {
          height: 110,
        },
        {
          height: 50,
        },
        {
          height: 24,
        },
        {
          height: 10,
        },
        {
          height: 6,
        },
        // {
        //   height: 4,
        // },
      ],
      agingRateIndexList: [
        // 0.6 —-0.5%。 0.7—1.5%。0.8–3%。0.9—6%。0.95—17%。1—29%。1.05—26%。 1.1—12%。1.2—-4%  1.25—2% 1.3—1%
        {
          value: "0.6",
          proportion: "0.5%",
          index: 0,
        },
        {
          value: "0.7",
          proportion: "1.5%",
          index: 1,
        },
        {
          value: "0.8",
          proportion: "3%",
          index: 2,
        },
        {
          value: "0.9",
          proportion: "6%",
          index: 3,
        },
        {
          value: "0.95",
          proportion: "17%",
          index: 4,
        },
        {
          value: "1",
          proportion: "29%",
          index: 5,
        },
        {
          value: "1.05",
          proportion: "26%",
          index: 6,
        },
        {
          value: "1.1",
          proportion: "12%",
          index: 7,
        },
        {
          value: "1.2",
          proportion: "4%",
          index: 8,
        },
        {
          value: "1.25",
          proportion: "2%",
          index: 9,
        },
        {
          value: "1.3",
          proportion: "1%",
          index: 10,
        },
      ],
      //   报告第四页柱状图高度
      tagingRateList2: [
        {
          height: 10,
        },
        {
          height: 40,
        },
        {
          height: 100,
        },
        {
          height: 240,
        },
        {
          height: 250,
        },
        {
          height: 260,
        },
        {
          height: 110,
        },
        {
          height: 50,
        },
        {
          height: 30,
        },
        {
          height: 20,
        },
      ],
      References: [],
    };
  },
  mounted() {
    this.References = References;
    let id = this.$route.query.id;
    this.getExportData(id);
  },
  methods: {
    printPdf2() {
      let loading = this.$loading({
        lock: true,
        text: "加载中，请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let fileName = this.reportInfo.name + "-" + this.reportInfo.sampleId;
      printPdf(document.getElementById("htmltopdfContent"), fileName, loading);
    },
    /**
     * 根据 id 获取报告信息
     */
    getExportData(id) {
      this.$http.get(`db-sample-data/getExportData?id=${id}`).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          // 处理报告第三页，根据衰老速率指数得到数据库占比及柱状图位置
          let item = this.agingRateIndexList.find(
            (item) => item.value == data.agingIndex
          );
          if (item) {
            data.agingIndexProportion = item.proportion;
            data.agingIndexIndex = item.index;
          }
          this.reportInfo = data;
          this.drawCircle(
            "circleCanvas",
            this.reportInfo.dnaBiologicalAgeRatio * 10 * 10 * 2,
            100,
            data.sex == "男" ? "#264596" : "#e07071"
          ); // 75/100 的进度
          let min = Math.min(data.age, data.dnaBiologicalAge);

          if (data.lastAge && data.lastDnaBiologicalAge) {
            min = Math.min(
              data.age,
              data.dnaBiologicalAge,
              data.lastAge,
              data.lastDnaBiologicalAge
            );
          }
          let max = Math.max(data.age, data.dnaBiologicalAge);

          if (data.lastAge && data.lastDnaBiologicalAge) {
            max = Math.max(
              data.age,
              data.dnaBiologicalAge,
              data.lastAge,
              data.lastDnaBiologicalAge
            );
          }
          let series = [
            {
              data: [data.age],
              type: "line",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 3, // 0.1的线条是非常细的了
                  },
                  borderColor: "#ff7f50", // 圆点边框颜色
                  borderWidth: 3, // 圆点边框宽度
                  color: "#ff7f50", // 圆点填充颜色
                },
              },
            },
            {
              data: [data.dnaBiologicalAge],
              type: "line",
              itemStyle: {
                normal: {
                  lineStyle: {
                    width: 3, // 0.1的线条是非常细的了
                  },
                  borderColor: "#55a868", // 圆点边框颜色
                  borderWidth: 5, // 圆点边框宽度
                  color: "#55a868", // 圆点填充颜色
                },
              },
            },
          ];

          if (data.lastAge) {
            series[0].data[1] = data.lastAge;
          }
          if (data.lastDnaBiologicalAge) {
            series[1].data[1] = data.lastDnaBiologicalAge;
          }
          let elementEcharts = this.$echarts.init(
            document.getElementById(`dataComparisonEchart`)
          );
          let xAxisData = [this.dateFormat4(data.reportTime)];
          if (data.reportTime) {
            xAxisData.push(this.dateFormat4(data.reportTime));
          }

          let option = {
            xAxis: {
              type: "category",
              data: xAxisData,
            },
            yAxis: {
              type: "value",
              min: min - 2,
              max: max + 2,
              interval: 1,
              axisLine: {
                show: false,
              },
            },
            series: series,
            color: ["#ff7f50", "#55a868"],
          };
          elementEcharts.setOption(option);
        }
      });
    },
    /**
     * 绘制第三页女性衰老时钟空心圆进度
     */
    drawCircle(canvasId, value, maxValue, strokeStyle) {
      var canvas = document.getElementById(canvasId);
      var ctx = canvas.getContext("2d");
      var centerX = canvas.width / 2;
      var centerY = canvas.height / 2;
      var radius = Math.min(centerX, centerY) - 5;

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
      ctx.fillStyle = "#d8d8d8";
      ctx.fill();

      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        radius - 25,
        -0.5 * Math.PI,
        (-0.5 + value / maxValue) * Math.PI
      );
      ctx.strokeStyle = strokeStyle || "#666";
      ctx.lineWidth = 50;
      ctx.stroke();
    },
    // 下载 pdf
    getPdfFromHtmlFun() {
      let loading = this.$loading({
        lock: true,
        text: "加载中，请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let fileName = this.reportInfo.name + "-" + this.reportInfo.sampleId;
      this.getPdfFromHtml(
        document.getElementById("htmltopdfContent"),
        fileName,
        loading
      );
    },
    // 预览 PDF
    printView() {
      let oldhtml = document.body.innerHTML;
      document.body.innerHTML =
        document.getElementById("htmltopdfContent").innerHTML;
      setTimeout(() => {
        window.print();
        document.body.innerHTML = oldhtml;
      }, 100);
      // this.pdfUrl = window.URL.createObjectURL(
      //   new Blob([content], { type: "application/pdf" })
      // );
      // var date = new Date().getTime();
      // var ifr = document.createElement("iframe");
      // ifr.style.frameborder = "no";
      // ifr.style.display = "none";
      // ifr.style.pageBreakBefore = "always";
      // ifr.setAttribute("id", "printPdf" + date);
      // ifr.setAttribute("name", "printPdf" + date);
      // ifr.src = this.pdfUrl;
      // document.body.appendChild(ifr);
      // window.URL.revokeObjectURL(ifr.src); // 释放URL 对象
      // var ordonnance =
      //   document.getElementById("htmltopdfContent").contentWindow;
      // setTimeout(() => {
      //   ordonnance.print();
      // }, 100);
    },
  },
};
</script>
<style lang="less" scoped>
.query {
  position: fixed;
  top: 120px;
  left: 240px;
  z-index: 1;
}
@import url(./less/htmltopdfContent.less);
</style>
